import React from 'react'
import { animated } from 'react-spring'

import PathAnimation from './pathAnimation'

const SVG = () => {
  return (
    <PathAnimation>
      {styles => (
        <animated.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 196.70863 29.151995"
          id="svg845"
          style={styles}
        >
          <g
            id="layer1"
            transform="translate(22.76 -147.109)"
            fill="none"
            stroke="#333"
            strokeWidth="0.529"
          >
            <path
              pathLength="100"
              id="outline"
              d="m 65.223648,168.59293 c -3.38319,-0.75265 -6.908469,-0.7406 -10.373299,-0.68667 -4.17822,0.065 -8.32385,0.75622 -12.47721,1.21691 -4.2818,0.47493 -8.542462,1.1313 -12.8255,1.59479 -3.431029,0.3713 -6.859251,0.87536 -10.309421,0.94993 -3.458638,0.0747 -6.907278,-0.44384 -10.3662003,-0.50482 -2.35835,-0.0416 -4.72118,-0.0494 -7.075479,0.0948 -2.60122004,0.15942 -5.24642,0.20618 -7.77206,0.85061 -3.24387,0.82755 -9.2896597,3.82397 -9.2896597,3.82397 0,0 -4.15188,-4.80281 -5.56115,-7.61357 -0.84364,-1.68263 -1.60599,-3.51602 -1.66284,-5.39859 -0.0533,-1.76567 0.29331,-3.66641 1.25121,-5.14841 1.41486,-2.18896 3.80043,-3.71112 6.166,-4.79444 10.0887897,-2.92987 21.1983897,-3.76543 30.864389,-4.4864 5.909111,-0.43509 11.83907,-0.52175 17.76158,-0.69527 6.165432,-0.18064 12.336351,-0.57531 18.50073,-0.35997 35.030059,1.22371 69.961652,1.88375 104.681022,6.69487 5.56811,0.77158 16.57168,3.12608 16.57168,3.12608 -12.53176,8.94282 -26.63076,11.25674 -40.19334,11.65038 l -55.760413,-0.39969 c -1.85036,0.23415 -3.3129,1.67024 -4.81981,2.80493 0,0 -4.80241,-2.16157 -7.310229,-2.71948 z"
            />
            <path
              pathLength="98"
              id="path2997-1"
              d="m 61.465672,158.37532 c 0.03583,0.91795 -0.676807,1.69113 -1.59171,1.72694 -0.914902,0.0358 -1.68562,-0.6793 -1.721445,-1.59725 -0.03583,-0.91795 0.676807,-1.69114 1.591709,-1.72695 0.914903,-0.0358 1.685621,0.6793 1.721446,1.59726 z"
              opacity="0.96"
            />
            <path
              pathLength="98"
              id="path2997-1-9"
              d="m 16.594164,159.73763 c 0.03583,0.91795 -0.676807,1.69113 -1.59171,1.72694 -0.914902,0.0358 -1.68562,-0.6793 -1.721445,-1.59725 -0.03583,-0.91795 0.676807,-1.69114 1.591709,-1.72695 0.914903,-0.0358 1.685621,0.6793 1.721446,1.59726 z"
              opacity="0.96"
            />
            <path
              pathLength="98"
              id="thong-tube"
              d="m -6.1060413,162.24403 c 0.0765,1.9593 -1.4446,3.6096 -3.3974,3.68604 -1.9527897,0.0764 -3.5978297,-1.44992 -3.6742997,-3.40922 -0.0765,-1.95931 1.44459,-3.60961 3.3973897,-3.68605 1.9528,-0.0764 3.59784,1.44992 3.67431,3.40923 z"
              opacity="0.96"
            />
            <path
              pathLength="100"
              d="m 79.008327,168.51934 1.74559,-4.03074 c 0.42057,-0.90765 1.05082,-1.35046 1.92611,-1.3437 l 53.362603,0.41203 c 12.46342,-0.75244 19.99695,-2.81565 29.25354,-7.92463"
              id="bevel"
            />
            <path
              pathLength="98"
              d="m 73.344553,159.03123 c 0.0765,1.9593 -1.4446,3.6096 -3.3974,3.68604 -1.952789,0.0764 -3.597829,-1.44992 -3.674299,-3.40922 -0.0765,-1.95931 1.44459,-3.60961 3.397389,-3.68605 1.9528,-0.0764 3.59784,1.44992 3.67431,3.40923 z"
              id="path1418"
              opacity="0.96"
            />
            <path
              pathLength="100"
              d="m 8.8697665,159.22375 c 0.120316,3.08151 -2.272005,5.67703 -5.343285,5.79725 -3.07126501,0.12016 -5.658513,-2.28037 -5.778782,-5.36188 -0.120316,-3.08151 2.27198899,-5.67704 5.343269,-5.79726 3.071281,-0.12016 5.658529,2.28037 5.778798,5.36189 z"
              id="path1420"
              opacity="0.96"
            />
            <path
              pathLength="100"
              id="path1422"
              d="m 33.627207,158.27881 c 0.120316,3.08151 -2.272005,5.67703 -5.343285,5.79725 -3.071265,0.12016 -5.658513,-2.28037 -5.778782,-5.36188 -0.120316,-3.08151 2.271989,-5.67704 5.343269,-5.79726 3.071281,-0.12016 5.658529,2.28037 5.778798,5.36189 z"
              opacity="0.96"
            />
            <path
              pathLength="100"
              d="m 52.14804,157.52285 c 0.120316,3.08151 -2.272005,5.67703 -5.343285,5.79725 -3.071265,0.12016 -5.658513,-2.28037 -5.778782,-5.36188 -0.120316,-3.08151 2.271989,-5.67704 5.343269,-5.79726 3.071281,-0.12016 5.658529,2.28037 5.778798,5.36189 z"
              id="path1424"
              opacity="0.96"
            />
          </g>
        </animated.svg>
      )}
    </PathAnimation>
  )
}
export default SVG
